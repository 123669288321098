
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




















































.pages-map,
[class*='pages-map--'] {
}

.map-outer {
  position: relative;
  // min-height: 50vh;
  height: calc(100vh - 12rem);
}

.map {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
