
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



































































































































































































.label {
  @extend %visually-hidden;
}

.trigger,
.list {
  border-radius: 1rem;
}

.list {
  overflow: hidden;
  transform: translateY(calc(100% + 1rem));
}

.option {
  &.is-active {
    background: $c-black-02;
  }

  &.is-selected {
    background: $c-black-05;
  }
}
