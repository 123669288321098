
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        









































































































































































































































































































































































$dd-c-white: rgb(255, 255, 255);
$dd-c-gray-light: rgb(215, 215, 215);
$dd-c-gray-lighter: rgb(235, 235, 235);

.default-trigger {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.33em 1em;
  background-color: $dd-c-white;
  border: 1px solid $dd-c-gray-light;

  svg {
    margin-left: auto;
  }

  &[aria-expanded] {
    svg {
      transform: rotate(180deg);
    }
  }
}

.default-option {
  padding: 0.33em 1em;
  background-color: $dd-c-white;

  &.is-active,
  &[aria-selected],
  &:hover {
    background-color: $dd-c-gray-lighter;
  }

  &.is-selected {
    background-color: $dd-c-gray-light;
  }
}

.dropdown {
  position: relative;
}

.label,
.select {
  display: block; // Some declaration to avoid undefined selector
}

.trigger,
.option {
  cursor: pointer;
}

.list {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(100%);
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: $dd-c-white;
  border: 1px solid $dd-c-gray-light;
}

/* stylelint-disable block-no-empty */
.option {
  &.is-active,
  &.is-selected {
    list-style-type: none; // Some declaration to avoid undefined selector
  }
}
/* stylelint-enable block-no-empty */
