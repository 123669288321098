
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        























































$bp-grid: 'm';
$bp-2col: 'm';
$bp-3col: 'widget2';
$bp-4col: 'xxxl';

.tiles-outer {
  // display: flex;
  // flex-wrap: wrap;
  // Float version
  overflow: hidden;
  padding-right: $spacing;
  padding-left: $spacing;

  > .tile-outer {
    position: relative;
    flex-basis: 100%;
    margin: $spacing / 2;

    @include mq($until: widget) {
      min-height: 36rem;
    }

    @include mq(widget) {
      height: 0;
      padding-top: 75%;
    }

    @include mq($bp-3col) {
      float: left;
      width: calc(33.33% - #{$spacing});
      height: 32rem;
      margin: $spacing * 0.5;
      padding-top: 0;
    }

    @include mq($bp-4col) {
      width: calc(25% - #{$spacing});
    }
  }

  .tile {
    position: absolute;
    inset: 0;
  }
}

[class*='tile-outer--'][class*='--2-1'] {
  @include mq($bp-3col) {
    width: calc(66.66% - #{$spacing});
  }

  @include mq($bp-4col) {
    width: calc(50% - #{$spacing});
  }
}

[class*='tile-outer--'][class*='--big'] {
  @include mq($bp-3col) {
    width: calc(66.66% - #{$spacing});
    /* stylelint-disable-next-line declaration-no-important */
    height: 64rem + $spacing !important;
  }

  @include mq($bp-4col) {
    width: calc(50% - #{$spacing});
    /* stylelint-disable-next-line declaration-no-important */
    height: 64rem + $spacing !important;
  }
}
